import React from 'react'
import styled from "styled-components"
import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

import Seo from "../components/Seo"
import PageTitle from '../components/PageTitle'
import NewsletterCTA from '../components/NewsletterCTA'

const EventsContainer = styled.section`
  margin-bottom: 120px;

  h2 {
    text-align: center;
    margin-bottom: 40px;
  }

  h3 {
    font-size: 26px;
    margin-bottom: 10px;
  }

  article {
    text-align: left;
    margin-bottom: 50px;

    &:last-child {
      margin-bottom: 0;
    }

    .gatsby-image-wrapper {
      margin-bottom: 20px;
      position: relative;

      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0.8;
        background-image: linear-gradient(225deg, #000000 0%, rgba(0,0,0,0.00) 47%, #000000 100%);
      }
    }

    .event-date,
    .event-location {
      margin-bottom: 15px;
      font-size: 17px;
      font-weight: var(--font-bold);
      color: var(--gray);

      span {
        color: var(--white);
        font-weight: var(--font-bold);
      }
    }

    a {
      color: var(--pink);
      font-size: 18px;
      font-weight: var(--bold);
    }
  }

  .past-events-container {
    margin-top: 120px;
  }
`

const EventsPage = (props) => {
  const futureEventsTotal = props.data.FutureEvents.edges
  const pastEventsTotal = props.data.PastEvents.edges

  return (
    <main>
      {props.data.pageTitleData.edges.map(({ node }) => (
        <PageTitle key={node.id} title={node.eventsPageTitle} paragraph={node.eventsPageTitleParagraph.eventsPageTitleParagraph} />
      ))}
      <EventsContainer>
        <div className="container">
          <div className="future-events-container">
            {futureEventsTotal.length === 0 ? '' : (<h2>Future Events</h2>)}
            {props.data.FutureEvents.edges.map(({ node }) => (
              <article key={node.slug} className="events-grid">
                <GatsbyImage image={node.featuredImage.gatsbyImageData} alt={node.featuredImage.title} />
                <h3>{node.title}</h3>
                <p className="event-date"><span>Date: </span>{node.startDate} {node.endDate ? ('to ' + node.endDate) : ''}</p>
                <p className="event-location"><span>Location: </span>{node.eventAddress ? node.eventAddress : ''}{node.eventCity ? (' ' + node.eventCity) : ''}{node.eventState ? (', ' + node.eventState) : ''}{node.eventZipcode ? (' ' + node.eventZipcode) : ''}</p>
                <a href={node.slug}>Event Details</a>
              </article>
            ))}
          </div>

          <div className="past-events-container">
            {pastEventsTotal.length === 0 ? '' : (<h2>Past Events</h2>)}
            {props.data.PastEvents.edges.map(({ node }) => (
              <article key={node.slug} className="events-grid">
                <GatsbyImage image={node.featuredImage.gatsbyImageData} alt={node.featuredImage.title} />
                <h3>{node.title}</h3>
                <p className="event-date"><span>Date: </span>{node.startDate} {node.endDate ? ('to ' + node.endDate) : ''}</p>
                <p className="event-location"><span>Location: </span>{node.eventAddress ? node.eventAddress : ''}{node.eventCity ? (' ' + node.eventCity) : ''}{node.eventState ? (', ' + node.eventState) : ''}{node.eventZipcode ? (' ' + node.eventZipcode) : ''}</p>
                <a href={node.slug}>Event Details</a>
              </article>
            ))}
          </div>
        </div>
      </EventsContainer>
      <NewsletterCTA />
    </main>
  )
}

export default EventsPage

export const Head = (seo) => {
  const seoData = seo.data.pageTitleData
  return (
    <>
      {seoData.edges.map(({ node }) => (
      <Seo key={node.id} title={node.seoTitle} description={node.seoDescription.seoDescription} image={node.seoImage.url} />
      ))}
    </>
  )
}

export const eventsContent = graphql`
  {
    pageTitleData: allContentfulEventsPage {
      edges {
        node {
          id
          eventsPageTitle
          eventsPageTitleParagraph {
            eventsPageTitleParagraph
          }
          seoTitle
          seoDescription {
            seoDescription
          }
          seoImage {
            url
          }
        }
      }
    }
    FutureEvents: allContentfulEvents(filter: {eventDateCategory: {eq: true}}) {
      edges {
        node {
          id
          title
          slug
          startDate(formatString: "MMMM DD, YYYY")
          endDate(formatString: "MMMM DD, YYYY")
          eventAddress
          eventCity
          eventState
          eventZipcode
          eventUrl
          featuredImage {
            gatsbyImageData(width: 1800, placeholder: BLURRED)
            title
          }
        }
      }
    }
    PastEvents: allContentfulEvents(
    filter: {eventDateCategory: {eq: false}}
    sort: {fields: startDate, order: DESC}
    ) {
      edges {
        node {
          id
          title
          slug
          startDate(formatString: "MMMM DD, YYYY")
          endDate(formatString: "MMMM DD, YYYY")
          eventAddress
          eventCity
          eventState
          eventZipcode
          eventUrl
          featuredImage {
            gatsbyImageData(width: 1800, placeholder: BLURRED)
            title
          }
        }
      }
    }
  }
`;